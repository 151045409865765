:root {
   --editorToolbarZIndex: 1000000;
   --electronTitlebarZIndex: 100;
   // TODO: These are dumb and should be done differently
   --settingsModalLevel1ZIndex: 10001;
   --settingsModalLevel2ZIndex: 10002;
   --settingsModalLevel3ZIndex: 10003;
   // Schedule
   --scheduleDatesHeaderZindex: 3;
   --scheduleTimeHeaderZindex: 2;
   --scheduleSessionZindex: 5;
   --tooltipZindex: 100000; // higher than modals
   --tvViewZindex: 100000; // Abover everything
}
