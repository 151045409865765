:root {
   --toolbarButtonIconColor: #fff;
   --toolbarButtonActiveBackgroundColor: rgba(255,255,255,0.1);
   --toolbarButtonActiveIconColor: #fff;
}

.gather-editor {
   h1 {
      .ui-title-lg;
   }

   h2 {
      .ui-title-md;
   }

   h3 {
      .ui-title-sm;
   }

   pre {
      border-radius: 3px;
      caret-color: red;
      overflow-x: auto;
      overflow-y: hidden;
      word-wrap: normal;
      font-size: 0.8rem;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
      padding: 15px 20px;
      background: rgb(41, 45, 62);
      color: rgb(191, 199, 213);
      line-height: 1.4;
   }
   
   *{
      -webkit-tap-highlight-color: transparent;
   }

   a {
      color: var(--linkColor);
      text-decoration: underline;
      cursor: pointer;
   }
   
   input {
      -webkit-appearance: none;
   }
   
   ol {
      margin: 0 0 0 30px;
      padding: 0;
   }
   
   ul {
      margin: 0 0 0 20px;
      padding: 0;
      list-style: none;
   }
   
   ul li:before {
      content: "• ";
      /* content: "— "; */
      position: absolute;
      top: 0px;
      left: -10px;
      font-size: 1rem;
      color: inherit;
   }
   
   li {
      position: relative;
      padding: 0px 0 0px 10px;
      margin: 0;
      font-size: 1rem;
   }

   .mc-checklist {
      margin: 10px 0px;
      padding: 0px;
   }
   
   .mc-checklist li:before {
      content: ""
   }
   
   .mc-checklist > li {
      position: relative;
      padding: 7px 0px 7px 30px;
      font-size: 0.95rem;
      border-bottom: 1px solid var(--lineColor);
   }
   
   .mc-checklist > li:last-child {
      border-bottom: none;
   }
   
   .mc-checklist > li input[type="checkbox"] {
      position: absolute;
      top: 0px;
      left: -2px;
      -webkit-appearance: checkbox;
      width: 28px;
      height: 28px;
      opacity: 0;
   }
   
   .mc-checkbox {
      position: absolute;
      top: 10px;
      left: 0px;
   }
   
   .mc-checklistitem-completed {
      color: #c6cfcd;
   }
   
   .mc-checkbox-icon {
      fill: #c6cfcd;
      stroke: #c6cfcd;
   }
   
   .mc-livetoken {
      display: inline-block;
      border-radius: 2px;
      vertical-align: baseline;
   }
   
   .mc-livetoken.active {
      box-Shadow: 0 0 0 2px #c9b36b;
   }
}

.mc-hovertoolbar {
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   position: absolute;
   z-index: var(--editorToolbarZIndex);
   top: -10000px;
   left: -10000px;
   margin-top: -6px;
   opacity: 0;
   background-color: rgba(46, 45, 51, 0.9);
   border-radius: 6px;
   transition: opacity 300ms;
   box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
   border: 1px solid var(--toolbarBorderColor);
   padding: 5px 5px;
   display: flex;
   align-items: center;
}

.mc-hovertoolbar.debug-mode {
   top: 0;
   left: 0;
   opacity: 1;
}

.mc-toolbar-button {
   border: 1px solid transparent;
   background-color: transparent;
   border-radius: 4px;
   margin: 0px 1px;
   width: 30px;
   height: 34px;
   font-size: 14px;
   color: var(--toolbarButtonIconColor);
   fill: var(--toolbarButtonIconColor);
   stroke: var(--toolbarButtonIconColor);
}

.mc-toolbar-button:hover {
   background-color: var(--toolbarButtonHoverBackgroundColor);
}

.mc-toolbar-button.active {
   background-color: var(--toolbarButtonActiveBackgroundColor);
   color: var(--toolbarButtonActiveIconColor);
   fill: var(--toolbarButtonActiveIconColor);
   stroke: var(--toolbarButtonActiveIconColor);
}

