.theme-mother-co, .theme-steven-yuen {
   // .discord;
   // .dark-mode;
   --avatarBorderRadius: 6px;
   --bodyTextSize: 13px;
   --baseComponentHeight-sm: 32px;
   --baseComponentHeight-md: 32px;
   // --titlebarHeight: 70px;
   --mainMenuWidth: 220px;

   // --mainMenuBackgroundColor: #fafafa;

   @media screen and (min-width: 1350px) {
      // --gatherAppPaddingHorizontal: 148px;
      // --gatherAppPaddingHorizontal: 60px;
   }
}
