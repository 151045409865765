:root {
   --bodyFontFamiy: inter, switzer, neue-haas-unica, Helvetica Neue, Helvetica, sans-serif;
   --titleFontFamily: inter, system-ui, switzer;
   --bodyTextLineHeight: 20px;
   --bodyTextSize: 14px;
   --bodyTextWeight: 420;
   --metaTextSize: 13px;
   --paragraphTextFontFamily: inherit;
   --paragraphTextLineHeight: 24px;
   --paragraphTextSize: 16px;
   --subtitleLineHeight: 28px;
   --subtitleTextSize: 20px;
   --subtitleSmallTextSize: 15px;
   --subtitleSmallLineHeight: 24px;
   --titleXlFontWeight: 550;
}
