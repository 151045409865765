.ui-listmenubutton {
   position: relative;
   display: inline-flex;
   align-items: center;
   border-radius: var(--listMenuButtonBorderRadius);
   width: 100%;
   color: var(--listMenuButtonTextColor);
   line-height: 120%;
   font-weight: 400;
   cursor: pointer;

   &:hover {
      background-color: var(--listMenuButtonActiveBackgroundColor);
      color: var(--listMenuButtonActiveTextColor);
      opacity: 0.8;
   }

   &.active {
      background-color: var(--listMenuButtonActiveBackgroundColor);
      color: var(--listMenuButtonActiveTextColor);
      font-weight: 600;

      .line { display: none; }
   }
}

.ui-tabmenubutton {
   position: relative;
   display: inline-flex;
   align-items: center;
   border-radius: var(--listMenuButtonBorderRadius);
   color: var(--listMenuButtonTextColor);
   line-height: 120%;
   font-weight: 400;
   cursor: pointer;

   &:hover {
      background-color: var(--listMenuButtonActiveBackgroundColor);
      color: var(--listMenuButtonActiveTextColor);
      opacity: 0.8;
   }

   &.active {
      background-color: var(--listMenuButtonActiveBackgroundColor);
      color: var(--listMenuButtonActiveTextColor);
      font-weight: 600;

      .line { display: none; }
   }
}
